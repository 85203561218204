<template>
    <v-container fluid>
        <div class="pl-4 pr-0 pt-2 d-flex">
            <div class="pr-0 pt-4 left_filter">
                <setting-menu></setting-menu>
            </div>
            <div class="div_body pl-3 pr-0 mx-0">
                <h1>
                    {{ $t('eav.entity_property') }}
                </h1>
                <v-form>
                    <ValidationObserver v-slot="{ invalid, validated, passes, validate }">
                        <ValidationProvider rules="required" v-slot="{ errors, valid }" ref="type">
                            <SelectInput
                                id="type"
                                v-model="type"
                                :label="$t('eav.type')"
                                :items="types"
                                :error="!valid"
                                :error-messages="errors"
                                hide-details
                                class="mb-4"
                            ></SelectInput>
                        </ValidationProvider>

                        <ValidationProvider rules="required" v-slot="{ errors, valid }" ref="slug">
                            <TextInput
                                id="slug"
                                v-model="slug"
                                :label="$t('eav.slug')"
                                :error="!valid"
                                :error-messages="errors"
                            ></TextInput>
                        </ValidationProvider>

                        <ValidationProvider rules="required" v-slot="{ errors, valid }" ref="sort">
                            <TextInput
                                id="sort"
                                v-model="sort"
                                :label="$t('eav.sort')"
                                :error="!valid"
                                :error-messages="errors"
                            ></TextInput>
                        </ValidationProvider>

                        <template v-for="lng in listLanguageNames">
                            <ValidationProvider rules="required" v-slot="{ errors, valid }" :ref="lng.id">
                                <TextInput
                                    :id="`name_${lng.id}`"
                                    :label="$t('field_name') + ' ' + lng.name"
                                    :error="!valid"
                                    :error-messages="errors"
                                    v-model="lng.value"
                                    require
                                ></TextInput>
                            </ValidationProvider>
                        </template>

                        <ValidationProvider rules="required" v-slot="{ errors, valid }" ref="required">
                            <v-checkbox
                                id="sort"
                                v-model="required"
                                :label="$t('eav.required')"
                                :error="!valid"
                                :error-messages="errors"
                                hide-details
                            ></v-checkbox>
                        </ValidationProvider>

                        <ValidationProvider rules="required" v-slot="{ errors, valid }" ref="multiple">
                            <v-checkbox
                                id="multiple"
                                v-model="multiple"
                                :label="$t('eav.multiple')"
                                :error="!valid"
                                :error-messages="errors"
                                hide-details
                                class="mb-4"
                            ></v-checkbox>
                        </ValidationProvider>

                        <Btn  :disabled="invalid" @click="postItem">{{ $t('create') }}</Btn>
                    </ValidationObserver>
                </v-form>
            </div>
        </div>
    </v-container>
</template>
<script>

import {mapGetters} from "vuex"
import {mask} from 'vue-the-mask'
import SettingMenu from "../components/SettingMenu";
import TextInput from "../components/Form/TextInput";
import SelectInput from "../components/Form/SelectInput";
import Btn from "@/components/Form/Btn.vue";
import {ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
    name: "Pages",

    props: {
        entity_type_slug: {
            type: String,
            required: true
        },

        id: {
            type: Number,
            required: true
        }
    },

    directives: {
        mask,
    },
    components: {
        Btn,
        SettingMenu,
        TextInput,
        SelectInput,
        ValidationObserver,
        ValidationProvider
    },
    data() {
        return {
            slug: null,
            sort: 500,
            multiple: false,
            required: false,
            type: null,
            name: {},
            errors: {},
            types: [
                {
                    text: 'Строка',
                    value: 'string'
                },
                {
                    text: 'Число',
                    value: 'number'
                },
                {
                    text: 'Логическое',
                    value: 'boolean'
                },
                {
                    text: 'Дата и время',
                    value: 'datetime'
                },
                // {
                //     text: 'Дата',
                //     value: 'date'
                // },
                // {
                //     text: 'Файл',
                //     value: 'file'
                // },
                // {
                //     text: 'Список',
                //     value: 'list'
                // },
            ],
        };
    },
    computed: {
        ...mapGetters(['lang', 'listLanguageNames']),
    },
    mounted() {
        this.clearForm();
        if(this.id > 0) {
            this.fetchItem();
        }
    },
    methods: {
        clearForm(){
            this.slug = null
            this.multiple =  false;
            this.required =  false;
            this.type = null;
            this.listLanguageNames.forEach((lang) => {
                lang.value = null;
            })
        },
        fetchItem() {
            this.$http.get(`admin/eav/${this.entity_type_slug}/property/${this.id}`)
                .then(response => {
                    let data = response.body.data;
                    this.slug = data.slug;
                    this.multiple = data.multiple;
                    this.required = data.required;
                    this.type = data.type;

                  //  let name = {};
                    this.listLanguageNames.forEach((lang) => {
                        lang.value = data.translations[lang.id].name;
                       // name[lang.id] = data.translations[lang.id].name;
                    })
                  //  this.$set(this, 'name', name);
                })
                .catch(errorResp => {
                    this.errors = errorResp.body.errors;
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        postItem() {
            this.loading = true;
            let data = {
                slug: this.slug,
                type: this.type,
                sort: this.sort,
                multiple: this.multiple,
                required: this.required,
            };

            this.listLanguageNames.forEach((lng) => {
                if (!data[lng.id]) {
                    data[lng.id] = {};
                }
                data[lng.id]['name'] = lng.value;
            })

            if(this.id > 0) {
                this.$http.put(`admin/eav/${this.entity_type_slug}/property/${this.id}`, data)
                    .then(response => {
                        this.items = response.data.data;
                        this.totalCountries = response.data.total;

                        this.$router.push({
                            name: 'eav.entity_type.property',
                            params: {
                                slug: this.entity_type_slug
                            }
                        });
                    })
                    .catch(errorResp => {
                        this.errors = errorResp.body.errors;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } else {
                this.$http.post(`admin/eav/${this.entity_type_slug}/property`, data)
                    .then(response => {
                        this.items = response.data.data;
                        this.totalCountries = response.data.total;

                        this.$router.push({
                            name: 'eav.entity_type.property',
                            params: {
                                slug: this.entity_type_slug
                            }
                        });
                    })
                    .catch(errorResp => {
                        this.errors = errorResp.body.errors;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
                }
        }
    }
}
</script>
